<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span class="text-h3">
          {{ readOnly ? $t("view_vehicle") : $t("edit") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="editVehicle.product"
                dense
                disabled
                :items="['oem', 'fleet']"
                item-text="name"
                item-value="name"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
                @change="
                  () => {
                    delete editVehicle.tenant_id;
                    // delete editVehicle.user_type;
                  }
                "
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="editVehicle.tenant_id"
                disabled
                dense
                :loading="tenantLoading"
                :items="tenantsByProduct"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.md_make_name"
                disabled
                dense
                :label="$t('ev_make_*')"
                :rules="[rules.required]"
              />
              <!-- <v-select
                v-model="editVehicle.md_make_id"
                disabled
                dense
                :items="mdMakesList"
                :loading="isLoadingMdMakes"
                item-value="id"
                item-text="name"
                :label="$t('ev_make_*')"
                :rules="[rules.required]"
              /> -->
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.md_model_name"
                disabled
                dense
                :label="$t('ev_model_*')"
                :rules="[rules.required]"
              />
              <!-- <v-select
                v-model="editVehicle.md_model_id"
                disabled
                dense
                :items="mdModelsList"
                :loading="isLoadingMdModels"
                item-value="id"
                item-text="name"
                :label="$t('ev_model_*')"
                :rules="[rules.required]"
              /> -->
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-if="readOnly && editVehicle.iot_device_id"
                dense
                :value="editVehicle.iotDevice.imei"
                :label="$t('iot_device_*')"
                disabled
              />
              <v-select
                v-else
                v-model="editVehicle.iot_device_id"
                dense
                :items="iotDevicesList"
                :loading="isLoadingIotDevices"
                item-value="id"
                item-text="imei"
                :disabled="readOnly"
                clearable
                :label="$t('iot_device')"
              />
              <!-- :rules="[rules.required]" -->
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.year"
                dense
                type="number"
                :label="$t('year_*')"
                :min="getStartingYear()"
                :max="getCurrentYear()"
                :disabled="readOnly"
                :rules="[rules.required, rules.min_year(), rules.max_year()]"
              />
            </v-col>
            <!-- <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.vin_no"
                dense
                :label="$t('vin_no_*')"
                :rules="[rules.required]"
              />
            </v-col> -->

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.registration_no"
                dense
                :label="$t('registration_no')"
                :rules="[rules.required]"
              />
              <!-- :rules="[rules.reg_num]" -->
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.chassis_no"
                dense
                :label="$t('chassis_no_*')"
                :disabled="readOnly"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editVehicle.motor_no"
                dense
                :label="$t('motor_no')"
                :disabled="readOnly"
              />
              <!-- :rules="[rules.required]" -->
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="editVehicle.vehicle_tech"
                dense
                disabled
                :items="['ev', 'ice']"
                item-value="id"
                item-text="name"
                :label="$t('vehicle_tech_*')"
                :rules="[rules.required]"
                @change="changeVehicleTech"
              />
            </v-col>
            <v-col
              v-if="editVehicle.vehicle_tech == 'ice'"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="editVehicle.fuel_sensor_installed"
                dense
                :items="['yes', 'no']"
                item-value="id"
                item-text="name"
                :disabled="readOnly"
                :label="$t('fuel_sensor_installed_*')"
                :rules="[rules.required]"
                @change="changeFuelInstalled"
              />
            </v-col>
            <template v-if="editVehicle.fuel_sensor_installed == 'yes'">
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="editVehicle.fuel_sensor_name"
                  dense
                  :items="['technoton', 'escort']"
                  item-value="id"
                  item-text="name"
                  :label="$t('fuel_sensor_name_*')"
                  :disabled="readOnly"
                  :rules="[rules.required]"
                  @change="changeFuelSenorName"
                />
              </v-col>
              <template v-if="editVehicle.fuel_sensor_name == 'escort'">
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel1a0"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}1a0 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel1a1"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}1a1 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel1a2"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}1a2 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel2a0"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}2a0 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel2a1"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}2a1 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="editVehicle.fuel2a2"
                    dense
                    :disabled="readOnly"
                    type="number"
                    :label="`${$t('fuel')}2a2 (L) *`"
                    :rules="[rules.required, rules.float(7)]"
                  />
                </v-col>
              </template>
              <!--  -->
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editVehicle.tank_capacity"
                  dense
                  :disabled="readOnly"
                  :label="$t('tank_capacity_*')"
                  :rules="[rules.required, rules.float(7)]"
                />
              </v-col>
            </template>
            <!--  -->
            <template v-if="editVehicle.vehicle_tech !== 'ice'">
              <v-col
                v-for="(battery, index) in editVehicle.battery"
                :key="index"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="battery.serial_no"
                  dense
                  :label="`Battery ${index + 1} - Serial No*`"
                  :disabled="readOnly"
                  :rules="[
                    rules.required,
                    (value) =>
                      isUniqueSerialNumber(value, index) ||
                      'Serial numbers must be unique',
                  ]"
                />
              </v-col>
            </template>

            <!--  -->
            <v-col cols="6">
              <v-checkbox
                v-model="editVehicle.send_data_to_third_party"
                hide-details
                :ripple="false"
                :label="$t('send_data_to_third_party')"
                :value="1"
              />
            </v-col>
            <template v-if="editVehicle.send_data_to_third_party">
              <v-col cols="6">
                <v-select
                  v-model="editVehicle.third_party_ids"
                  dense
                  :loading="isThirdParty"
                  :items="thirdPartList"
                  item-value="id"
                  item-text="title"
                  :label="$t('select_third_party_*')"
                  multiple
                  :rules="[rules.multi_select_required]"
                />
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleEdit()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="isLoadingSave"
          @click="updateItem(editVehicle)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    editVehicle: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      iotDevicesList: "iotDevices/getIotDevicesList",
      isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      mdModelsList: "mdModels/activelistMdModels",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      //
      isThirdParty: "vehicles/isThirdParty",
      thirdPartList: "vehicles/getThirdPartyList",
    }),

    tenantsByProduct() {
      if (this.editVehicle?.product) {
        if (this.editVehicle.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },

    isMdModelHasFixedBatterySlot() {
      var mdModel = this.mdModelsList.find(
        (item) => item.id === this.editVehicle.md_model_id
      );

      if (mdModel) {
        return mdModel.battery_slot_type == "fixed";
      }

      return false;
    },
  },

  watch: {
    // "editVehicle.md_make_id": {
    //   handler: async function (value) {
    //     this.$store.dispatch("mdModels/list", { md_make_id: value });
    //   },
    // },
    "editVehicle.send_data_to_third_party": {
      handler: async function (val) {
        if (val) {
          await this.$store.dispatch("vehicles/thirdPartiesList");
        } else {
          this.editVehicle.send_data_to_third_party = 0;
          delete this.editVehicle.third_party_ids;
        }
      },
    },

    "editVehicle.vehicle_tech": {
      handler: function (val) {
        if (val == "ice") {
          this.vehicle.fuel1a0 = "0";
          this.vehicle.fuel1a1 = "0";
          this.vehicle.fuel1a2 = "0";
          //
          this.vehicle.fuel2a0 = "0";
          this.vehicle.fuel2a1 = "0";
          this.vehicle.fuel2a2 = "0";
        } else {
          delete this.vehicle.fuel_sensor_name;
          //
          delete this.vehicle.fuel1a0;
          delete this.vehicle.fuel1a1;
          delete this.vehicle.fuel1a2;
          //
          delete this.vehicle.fuel2a0;
          delete this.vehicle.fuel2a1;
          delete this.vehicle.fuel2a2;
        }
      },
    },
  },
  async mounted() {
    const params = {
      tenant_id: this.editVehicle.tenant_id,
      vehicle_assigned_to: this.editVehicle?.iotDevice?.id ?? false,
    };
    await this.$store.dispatch("iotDevices/list", params);
    await this.$store.dispatch("meta/getClients", "tenant");

    // for third party data
    if (this.editVehicle.send_data_to_third_party) {
      await this.$store.dispatch("vehicles/thirdPartiesList");
    }
  },

  methods: {
    async updateItem(editVehicle) {
      editVehicle.battery_serial_numbers = editVehicle.battery.map(
        (battery) => battery.serial_no
      );
      delete editVehicle.master_switch;
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("vehicles/update", editVehicle)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    isUniqueSerialNumber(serialNumber, index) {
      const duplicates = this.editVehicle.battery.filter(
        (battery, i) => battery.serial_no === serialNumber && i !== index
      );
      return duplicates.length === 0;
    },
    //
    changeVehicleTech() {
      delete this.vehicle.fuel_sensor_installed;
      this.changeFuelInstalled();
    },
    changeFuelInstalled() {
      delete this.vehicle.fuel_sensor_name;
      this.changeFuelSenorName();
    },
    changeFuelSenorName(v) {
      if (v == "escort") {
        this.vehicle.fuel1a0 = "0";
        this.vehicle.fuel1a1 = "0";
        this.vehicle.fuel1a2 = "0";
        //
        this.vehicle.fuel2a0 = "0";
        this.vehicle.fuel2a1 = "0";
        this.vehicle.fuel2a2 = "0";
      } else {
        delete this.vehicle.fuel1a0;
        delete this.vehicle.fuel1a1;
        delete this.vehicle.fuel1a2;
        //
        delete this.vehicle.fuel2a0;
        delete this.vehicle.fuel2a1;
        delete this.vehicle.fuel2a2;
      }
    },
    checkMM(val) {
      return (v) => {
        if (val) {
          if (parseInt(v) > parseInt(val)) {
            return true;
          } else {
            return `Should be greater than ${val}`;
          }
        } else {
          return "should be less than Maintenance Mileage";
        }
      };
    },
  },
};
</script>
